import React from 'react'
import { graphql } from 'gatsby'

import enMessages from '../i18n/schedule.en.json'
import plMessages from '../i18n/schedule.pl.json'
import itMessages from '../i18n/schedule.it.json'
import Layout from '../components/layout'
import SingleHeader from '../components/single-header'
import Timeline from '../components/timeline'
import Footer from '../components/footer'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
}

const SchedulePage = ({ pageContext: { locale }, data }) => (
  <Layout locale={locale}>
    <SingleHeader
      locale={locale}
      img={data.locationImage}
      title={messages[locale].title}
    />

    <div className="page_content">
      <div className="full_width_centered">
        <Timeline locale={locale} />
      </div>
    </div>
    <Footer locale={locale} />
  </Layout>
)

export default SchedulePage

export const pageQuery = graphql`
  query {
    locationImage: file(relativePath: { eq: "schedule/schedule.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 369, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
