import React from 'react'
import './timeline.css'

import enMessages from '../i18n/schedule.en.json'
import plMessages from '../i18n/schedule.pl.json'
import itMessages from '../i18n/schedule.it.json'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
}

const Timeline = ({ locale }) => (
  <ul className="timeline timeline-split">
    <li className="timeline-item period">
      <div className="timeline-info" />
      <div className="timeline-marker" />
      <div className="timeline-content">
        <h2 className="timeline-title">{messages[locale].ceremony.date}</h2>
      </div>
    </li>
    <li className="timeline-item">
      <div className="timeline-info">
        <span>{messages[locale].ceremony.time}</span>
      </div>
      <div className="timeline-marker" />
      <div className="timeline-content">
        <h3 className="timeline-title">{messages[locale].ceremony.title}</h3>
        <p>{messages[locale].ceremony.description}</p>
      </div>
    </li>
    <li className="timeline-item">
      <div className="timeline-info">
        <span>{messages[locale].reception.time}</span>
      </div>
      <div className="timeline-marker" />
      <div className="timeline-content">
        <h3 className="timeline-title">{messages[locale].reception.title}</h3>
        <p>{messages[locale].reception.description}</p>
      </div>
    </li>
    <li className="timeline-item period">
      <div className="timeline-info" />
      <div className="timeline-marker" />
      <div className="timeline-content">
        <h2 className="timeline-title">{messages[locale].secondday.date}</h2>
      </div>
    </li>
    <li className="timeline-item">
      <div className="timeline-info">
        <span>{messages[locale].secondday.time}</span>
      </div>
      <div className="timeline-marker" />
      <div className="timeline-content">
        <h3 className="timeline-title">{messages[locale].secondday.title}</h3>
        <p>{messages[locale].secondday.description}</p>
      </div>
    </li>
    <li className="timeline-item">
      <div className="timeline-info" />
      <div className="timeline-marker" />
      <div className="timeline-content">
        <h3 className="timeline-title">{messages[locale].happy.title}</h3>
      </div>
    </li>
  </ul>
)

export default Timeline
